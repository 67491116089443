.card {
  width: 347px;
  height: 244px;
  max-width: 392px;

  background: #ffff;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.cardLoad {
  width: 100%;
  height: 181px;

  background: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  border-radius: 10px 10px 0 0;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: loadAnimation 2s infinite;
}

.cardLoadTitle {
  width: 100px;
  height: 20px;
  border-radius: 5px;
  background: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: loadAnimation 2s infinite;
  margin-top: 10px;
  margin-left: 14px;
}

.cardLoadDescripion {
  width: 170px;
  height: 16px;
  border-radius: 5px;
  background: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  margin-top: 7px;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: loadAnimation 2s infinite;
  margin-left: 14px;
}

@keyframes loadAnimation {
  100% {
    background-position: -100% 0;
  }
}

@media (min-width: 768px) {
  .card {
    max-width: 347px;
  }
}

@media (min-width: 1333px) {
  .card {
    max-width: 310px;
  }
}
