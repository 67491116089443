.reserveDetailContainer {
  background-color: var(--white-color);
  padding: 31px 15px 18px;
  /* padding: 18px 18px 39px; */
  box-shadow: 0px 1px 5px rgb(0 0 0 / 15%);
  border-radius: 5px;
  margin-top: 15px;
  max-width: 708px; /* chequear cuando mejore el responsive */
}

.reserveDetailContainer h2 {
  margin-bottom: 14px;
  font-size: 20px;
}

.lineBooking {
  height: 1px;
  background-color: #bebebe;
  border: 0;
}

.categoryTitleContainer {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 20px;
  color: var(--secondary-color);
  margin: 15px 0 10px;
}

.productCategory {
  font-size: 14px;
  opacity: 0.5;
}

.productTitle {
  font-size: 20px;
}

.fullAddress {
  font-size: 14px;
  font-weight: 500;
  color: var(--secondary-color);
  display: flex;
  align-items: center;
}

.fullAddress svg {
  margin-right: 7px;
  align-self: flex-start;
}

.containerCheck {
  margin: 20px 0 36px;
}

.checkDate {
  display: flex;
  justify-content: space-between;
  padding: 19px 0 10px;
}

.checkDate p:nth-child(1) {
  font-weight: 700;
  color: #4f4f50;
}

.checkDate p:nth-child(2) {
  font-size: 14px;
  font-weight: 500;
  color: var(--secondary-color);
}

/* Image */
.productImageContainer {
  position: relative;
}

.productImage {
  width: 100%;
  height: 269px; /* ver */
  object-fit: cover;
  user-select: none;
}

.reserveDetailContainer button {
  height: 50px;
}

@media (min-width: 768px) {
  .reserveDetailContainer {
    padding: 24px 21px 36px 29px;
    width: 100%; /* revisar*/
    max-width: none; /* revisar*/
  }
  .reserveDetailContainer h2 {
    font-size: 24px;
  }
  .cardDetailContainer {
    display: flex;
    gap: 15px;
  }
  .productImageContainer {
    flex: 1 1;
  }
  .cardDetailRigth {
    flex: 1 1;
  }

  .categoryTitleContainer {
    margin: 0 0 10px;
  }
  .productTitle {
    font-size: 24px;
  }
  .fullAddress {
    height: 32px;
  }
  .fullAddress p {
    align-self: start;
  }
  .containerCheck {
    margin: 23px 0 25px;
  }
  .productImage {
    height: 312px;
    border-radius: 10px;
  }
}

@media (min-width: 1333px) {
  .reserveDetailContainer {
    margin-top: 44px;
    padding: 24px 15px 18px;
    min-width: 409px;
    width: 100%;
  }
  .cardDetailContainer {
    display: block;
  }

  .reserveDetailContainer h2 {
    margin-bottom: 24px;
  }
  .productImageContainer {
    margin-bottom: 43px;
  }
  .productImage {
    height: 300px;
  }

  .containerCheck {
    margin: 44px 0;
  }
  .checkDate {
    padding: 19px 0 30px;
  }
}
