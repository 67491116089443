.searchContainer {
  background-color: var(--secondary-color);
  padding: 24px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.searchTitle {
  color: var(--white-color);
  padding-bottom: 23px;
  font-size: 30px;
  font-style: normal;
  line-height: 35px;
  text-align: center;
  max-width: 394px;
}

.searchForm {
  display: flex;
  flex-direction: column;
  gap: 9px;
  max-width: 394px;
  width: 100%;
}

.selectCity {
  padding: 0 18px;
  border: none;
  border-radius: 5px;
  height: 40px;
  /* margin-bottom: 5px; */
  width: 100%;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
  outline: 0;
}

.selectCity:required:invalid {
  color: #666;
}
.selectCity option[value=''][disabled] {
  display: none;
}
.selectCity option {
  color: #000;
}

.searchBtn {
  width: 100%;
}

@media (min-width: 768px) {
  .searchContainer {
    height: 139px;
  }
  .searchTitle {
    max-width: 768px;
  }
  .searchForm {
    flex-direction: row;
    width: 100%;
    max-width: 768px;
    padding: 0 30px;
    /* padding: 0 149px; */
  }
  .searchBtn {
    max-width: 166px;
  }
}

@media (min-width: 1333px) {
  .searchContainer {
    height: 149px;
  }
  .selectCity {
    max-width: 422px;
  }
  .searchForm {
    max-width: 1130px;
  }
  .searchBtn {
    max-width: 206px;
  }
}
