.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(80vh);
  padding: 12px;
}

.card {
  width: 357px;
  height: 300px;
  border: 1px solid #dfe4ea;
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px 15px 30px;
}

.card svg {
  margin-bottom: 25px;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  height: 70px;
  margin-bottom: 19px;
}

.gratefulness {
  font-weight: 700;
  font-size: 20px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: var(--primary-color);
}

.successful {
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: rgb(49, 54, 63);
  color: var(--secondary-color);
}

@media screen and (min-width: 768px) {
  .card {
    width: 638px;
  }
  .gratefulness {
    font-size: 24px;
  }
  .successful {
    font-size: 20px;
  }
}
