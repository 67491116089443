.container {
  height: calc(100vh - 156px);
  color: var(--thirth-color);
  padding: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.container svg {
  width: 50px;
  height: 50px;
  color: var(--primary-color);
}

.container a {
  color: var(--secondary-color);
  transition: ease-in-out 200ms;
}

.container a:hover {
  color: var(--primary-color-hover);
}
