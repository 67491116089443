.container {
  padding-bottom: 50px;
}

.container section {
  padding: 12px;
}

.locationScoreContainer {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  background-color: rgba(49, 54, 63, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 14px;
  font-weight: 500;
  color: var(--secondary-color);
}

.locationContainer {
  display: flex;
  max-width: 60%;
}

.location {
  padding-left: 6px;
}

.locationDistance {
  display: none;
}

.productScore {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 700;
  min-width: fit-content;
}

.productStars {
  color: var(--primary-color);
  font-size: 14px;
}

.scoreNumber {
  min-width: fit-content;
  text-align: center;
  width: 34px;
  background: var(--secondary-color);
  color: var(--white-color);
  font-size: 20px;
  border-radius: 10px;
  padding: 7px; /* revisar */
  /* height: 100%; */
}

.container section.galleryContainer {
  padding: 0;
}

.galleryContainer {
  background: var(--white-color);
  opacity: 0.95;
  backdrop-filter: blur(250px);
  display: flex;
  /* align-items: center; */
  justify-content: center;
}

/* ************ */

/* Seccion Reserva-calendario */
.availableDatesContainer {
  display: flex;
  flex-direction: column;
  background: rgba(56, 59, 88, 0.1);
  font-weight: 700;
  font-size: 16px;
}

.availableDatesContainer h2 {
  padding: 25px 0 15px;
}

.reserveContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 30px 0;
}
/************/

.line {
  border: solid 1px var(--primary-color);
  outline: none;
  background: var(--primary-color);
  margin: 12px 0 15px;
}

@media (min-width: 768px) {
  .container section {
    padding: 12px 30px;
  }

  .locationDistance {
    display: block;
  }

  .availableDatesContainer h2 {
    padding: 20px 0;
  }

  .reserveContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1333px) {
  .container section {
    padding: 12px 40px;
  }

  .container section.galleryContainer {
    padding: 12px 40px;
  }

  .availableDatesContainer {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-rows: 0.2fr 1.8fr;
    gap: 0px 10px;
    flex-wrap: wrap;
  }

  .availableDatesContainer h2 {
    padding: 20px 0;
    width: 100%;
  }

  .calendarReserve {
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    gap: 30px;
  }

  .reserveContainer {
    display: flex;
    flex-direction: column;
    background-color: var(--white-color);
    border: 1px solid var(--white-color);
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 8px;
    padding: 26px 16px 15px;
    gap: 30px;
  }
}
