.container {
  padding-bottom: 50px;
  background: rgb(56 59 88 / 10%);
}

.container section {
  padding: 12px;
}

.reserveDateContainer h2 {
  margin-bottom: 15px;
}

.reserveDateContainer > .react-datepicker {
  border-radius: 5px;
}

@media (min-width: 768px) {
  .container section {
    padding: 12px 30px;
  }
}

@media (min-width: 1333px) {
  .container section {
    padding: 18px 20px;
  }
  .centerContainer {
    display: flex;
    margin-top: 18px;
  }
  .leftCenterContainer {
    flex: 1;
  }
  .rightCenterContainer {
    flex: 0.5;
  }
}
