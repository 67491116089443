.container {
  width: 100%;
  max-width: 394px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 21px;
  /* padding: 36px 12px 62px; */
  padding: 0 12px;
  margin: auto;
}

.recommendations {
  color: var(--white-color);
}

.msgNoProducts {
  color: var(--secondary-color);
  font-weight: 500;
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .container {
    max-width: 735px;
  }
  .recommendations {
    color: var(--secondary-color);
  }
}
@media (min-width: 1333px) {
  .container {
    max-width: 1324px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .recommendations {
    width: 100%;
  }
}
