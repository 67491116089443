.container {
  padding: 19px 12px 62px;
  /* margin: auto; */
  background: var(--secondary-color);
  padding-bottom: 25px;
}

@media (min-width: 768px) {
  .container {
    background: var(--light-grey-color);
  }
}
