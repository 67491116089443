.containerPolicy {
  font-weight: 500;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.containerPolicy textarea {
  /* width: 100%; */
  width: 360px;
  height: 120px;
  resize: none;
  color: var(--secondary-color);
  font-size: 12px;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  padding: 18px 15px 12px;
  box-shadow: 0px 1px 5px rgb(0 0 0 / 15%);
}

.containerPolicy textarea:focus {
  outline: none;
  background-color: var(--white-color);
}

.containerPolicy textarea::placeholder {
  color: #bebebe;
  font-weight: 500;
}

.containerPolicy h3 {
  font-size: 14px;
  font-weight: 700;
  color: var(--secondary-color);
  width: fit-content;
}

.containerPolicy p {
  font-size: 12px;
  color: var(--thirth-color);
  width: fit-content;
}

@media (min-width: 1333px) {
  .containerPolicy textarea {
    height: 200px;
  }
}
