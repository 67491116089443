@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,300&display=swap');

:root {
  --primary-color: #f0572d; /* orange-color */
  --primary-color-hover: #d33c13;
  --secondary-color: #31363f; /* dark-grey-color */
  --thirth-color: #191b1d; /* dark-cyanblue-color */
  --light-grey-color: #dfe4ea;
  --white-color: #ffffff;
  --red-color: #ff0000;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::after,
::before {
  box-sizing: border-box;
}

body {
  height: 100vh;
  background-color: var(--white-color);
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

/* overlay for when the menu is opened*/
body .overlay {
  background-color: rgba(0, 0, 0, 0.75);
  height: 100%;
  opacity: 0;
  position: fixed; /*absolute no funciona al scroll*/
  transition: opacity 250ms ease;
  width: 100%;
  z-index: -1;
}
body .overlay.visible {
  opacity: 0.5;
  z-index: 50;
  width: calc(100% - 303px);
  transition: all 0.5s;
}
