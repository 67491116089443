header {
  position: sticky;
  top: 0;
  z-index: 10;
}

.nav {
  background-color: var(--white-color);
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1.25rem 0.7rem;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 4px 5px rgba(0, 0, 0, 0.12),
    0px 1px 10px rgba(0, 0, 0, 0.2);
}

.containerLeft {
  display: flex;
  height: 56px;
}

.motto {
  display: none;
}

@media (min-width: 768px) {
  .nav {
    padding: 1.25rem 2rem;
  }
}

@media (min-width: 1333px) {
  .nav {
    padding: 1.25rem 2.5rem;
  }
  .motto {
    display: block;
    font-style: italic;
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 7px;
    margin-left: 12px;
    align-self: end;
    color: var(--thirth-color);
  }
}
