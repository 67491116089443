.container {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  text-align: end;
  font-weight: 700;
  color: var(--secondary-color);
}

.container p {
  margin-bottom: 10px;
}

.initialsContainer {
  background: white;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin: 10px 0;
}

.greeting {
  color: var(--white-color);
}

.containerRole {
  display: none;
}

.textRole {
  color: var(--secondary-color);
  margin-bottom: 10px;
}

.lineOrangeVertical {
  display: block;
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
  /* width: 100%; */
  height: 41px;
  margin-bottom: 7px;
}

@media (min-width: 768px) {
  .container {
    flex-direction: row;
    text-align: start;
    gap: 0.625rem;
    color: var(--primary-color);
  }

  .containerRole {
    display: flex;
    align-items: flex-end;
    gap: 20px;
    margin-right: 14px;
  }

  .initialsContainer {
    background-color: var(--secondary-color);
    color: var(--white-color);
  }
  .greeting {
    /* color: var(--secondary-color); */
    color: rgba(0, 0, 0, 0.5);
  }
}
